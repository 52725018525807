"use client";
import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Locale} from "@/i18n";
import Link from "next/link";

interface ClientSwiperSectionProps {
	locale: Locale;
	spaceBetween?: number;
	slidesPerView?: number;
	loop?: boolean;
	breakpoints?: any;
	items: {
		imgLink: string;
		label: string;
		url?: string | null;
	}[];
}

const ClientSwiperSection: FC<ClientSwiperSectionProps> = ({
	                                                           locale,
   spaceBetween = 30,
   slidesPerView = 2,
   loop = true,
   breakpoints = {
       576: { slidesPerView: 2 },
       768: { slidesPerView: 3 },
       992: { slidesPerView: 4 },
       1200: { slidesPerView: 6 },
   },
   items,
}) => {
	return (
		<Swiper
			spaceBetween={spaceBetween}
			slidesPerView={slidesPerView}
			breakpoints={breakpoints}
			loop={loop}
		>
			{items.map((item, index) => (
				<SwiperSlide className="swiper-slide" key={index}>
					{
						item.url == null ?
							<img src={item.imgLink} className="grayscale px-sm-4 ps-0" alt={item.label}/>
							:
							<Link href={item.url} locale={locale}>
								<picture>
									<img src={item.imgLink} className="grayscale px-sm-4 ps-0" alt={item.label}/>
								</picture>
							</Link>
					}
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default ClientSwiperSection;